var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm._l(_vm.categories, function (category, i) {
    return [category.itemType == _vm.STORY_ITEM_TYPES.SLIDE.value ? [_c('article-slides', _vm._b({
      key: category._id,
      class: {
        'pt-40px pt-lg-60px': 0 < i
      }
    }, 'article-slides', {
      category
    }, false))] : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }